/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithubSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons'

import { rhythm } from "../utils/typography"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={{
              display: `flex`,
              marginBottom: rhythm(2.5),
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                minWidth: 50,
                borderRadius: `100%`,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            <div>
              <div style={{ display: `flex`, alignItems: `center` }}>
                <div style={{ marginRight: `1rem` }}>
                  <strong>{author}</strong>
                </div>
                <a href={`https://github.com/${social.github}`} style={{ boxShadow: `none` }} >
                  <FontAwesomeIcon
                    icon={faGithubSquare}
                    style={{
                      color: `var(--github-square)`,
                      width: `32px`,
                      height: `32px`,
                      marginRight: `4px`,
                    }}
                  />
                </a>
                <a href={`https://twitter.com/${social.twitter}`} style={{ boxShadow: `none` }} >
                  <FontAwesomeIcon
                    color="#3eaded"
                    icon={faTwitterSquare}
                    style={{
                      width: `32px`,
                      height: `32px`,
                      marginRight: `4px`,
                    }}
                  />
                </a>
                <a href={`https://qiita.com/${social.qiita}`} style={{ boxShadow: `none`, marginBottom: 0 }} >
                  <Image
                    fixed={data.qiita.childImageSharp.fixed}
                    alt='qiita'
                    style={{
                      borderRadius: `4px`,
                      padding: `2px`,
                      marginBottom: `0`,
                    }}
                  />
                </a>
              </div>
              都内でRailsエンジニアしています。綺麗なコード書きたい。
            </div>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    qiita: file(absolutePath: { regex: "/qiita-icon.png/" }) {
      childImageSharp {
        fixed(width: 28, height: 28) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
          github
          qiita
        }
      }
    }
  }
`

export default Bio
