import React from "react"
import { Link } from "gatsby"
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { rhythm, scale } from "../utils/typography"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'

import { StyledThemeToggler } from '../styles/StyledThemeToggler'

class Header extends React.Component {
  render() {
    const { location, title } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3
          style={{
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <header style={{ display: `flex`, justifyContent: `space-between` }}>
        {header}
        <ThemeToggler>
          {({ theme, toggleTheme }) => (
            <StyledThemeToggler theme={theme}>
              <FontAwesomeIcon
                color="#F5F3CE"
                icon={faMoon}
                style={{
                  width: `22px`,
                  height: `22px`,
                }}
              />
              <input
                type="checkbox"
                onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                checked={theme === 'dark'}
                style={{ opacity: 0, width: `8px` }}
              />
              <FontAwesomeIcon
                color="#FFFF00"
                icon={faSun}
                style={{
                  width: `22px`,
                  height: `22px`,
                }}
              />
            </StyledThemeToggler>
          )}
        </ThemeToggler>
      </header>
    )
  }
}

export default Header