import styled from 'styled-components';

export const StyledThemeToggler = styled.label`
  width: 70px;
  height: 32px;
  background-color: ${props => props.theme === 'dark' ? '#252850' : '#87CEFA'};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 32px;
  border: 1px solid #aeaeae;
  transition: .4s;
  box-sizing: border-box;
  &::after {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    left: ${props => props.theme === 'dark' ? '38px' : '-1px'};
    top: -1px;
    z-index: 2;
    background: ${props => props.theme === 'dark' ? '#aeaeae' : '#fff'};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: .4s;
  }
`;